const apiBaseURL = "https://subbroker.adityatrading.in/api/"
export const sendOTPURL = apiBaseURL + "generate-one-time-password/"
export const submitUserInfoURL = apiBaseURL + "submit-user-info/"
export const checkOTPURL = apiBaseURL + "check-one-time-password/"
export const getDataUrl = apiBaseURL + "get-referral/"
export const getPaymentURL = apiBaseURL + "get-payment/"
export const getPaymentProfileURL = apiBaseURL + "get-payment-profile/"
// export const getRemisherLedgerURL = "https://synapses.adityatrading.in/api/getRemeshireLedger"
export const getRemisherLedgerURL = "https://synapses.adityatrading.in/api/getRemeshireLedgerData"
export const getRemeshireBrokerageURL = "https://synapses.adityatrading.in/api/getRemeshireBrokerage"
export const getOpenPositionURL = 'https://synapses.adityatrading.in/api/getOpenPosition'
export const getpandlURL = 'https://synapses.adityatrading.in/api/getplforaxon1'
export const getNewpandlURL = 'https://backoffice.adityatrading.in/get-position-summary?'
// export const getholdingsURL = 'https://synapses.adityatrading.in/api/reports/generic/dynamic/report_actions/currentholdingreport'
// export const getholdingsURL = 'https://synapses.adityatrading.in/api/reports/generic/dynamic/report_actions/currentholdingreport'
export const getholdingsURL = 'https://neuron.adityatrading.in/employeecrm/api/get-client-holdings-data?'
export const getProfileURL = apiBaseURL + "get-profile/"
export const getHistoricalReferralGraphURL = apiBaseURL + "get-historical-referral-data/"
export const getReferralURL = apiBaseURL + "get-referral/"
export const checkUserSessionURL = "https://subbroker.adityatrading.in/check-user-session/"
export const baseUrl = "https://kyc.adityatrading.in/referral/"
export const updateUserProfileURL = apiBaseURL + "update-profile/"
export const updatePaymentProfileURL = apiBaseURL + "update-payment-profile/"
export const updatePaymentURL = apiBaseURL + "update-payment/"
export const signOutURL = apiBaseURL + "signout-user/"
export const getSubbrokerContactsURL = "https://synapses.adityatrading.in/api/fast/getSub_broker_clients_details/?"
export const getContractNoteURL = "https://synapses.adityatrading.in/api/getcontracturleach"

export const whatsappShareBaseURL = "https://api.whatsapp.com/send?text="
export const fbShareBaseURL = "https://www.facebook.com/sharer.php?u="
export const twitterShareBaseURL = "https://twitter.com/intent/tweet?url="
export const twitterShareEndURL = "&text=Referral%20Link"

export const synapsesKycFilter = "https://synapses.adityatrading.in/api/kycfilter"
export const synapsesGetUserUrl = "https://synapses.adityatrading.in/api/loginkyc?"

export const neuronClientMarginUrl = "https://neuron.adityatrading.in/get-rms-balance/?"
export const neuronPayoutUrl = "https://neuron.adityatrading.in/api/payout"
export const neuronGetPayoutDetailsUrl = "https://neuron.adityatrading.in/api/get-payout-by-clientcode?"

// export const pledgeHoldingsUrl = "https://server.adityatrading.in/get-holdings-with-pnl/?"
export const pledgeHoldingsUrl = "https://adityatrading.in/api/get-holdings-with-pnl?"
export const pledgeUnpledgeUrl = "https://server.adityatrading.in/pledge-event/?"

export const synapsesTokenUser = "atsadmin"
export const synapsesTokenPass = "@ts@dmin123"
export const synapsesTokenUrl = "https://synapses.adityatrading.in/api/token/"

export const synapsesFastTokenUser = "atsadmin"
export const synapsesFastTokenPass = "@ts@dmin123"
export const synapsesFastTokenUrl = "https://synapses.adityatrading.in/api/fast/generate_token/?"   