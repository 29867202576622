import React, { useEffect, useState } from 'react'

import { UsersIcon, ArrowSmUpIcon, CurrencyRupeeIcon, ReceiptTaxIcon, CreditCardIcon, ClipboardCopyIcon } from '@heroicons/react/solid'

import axios from 'axios'
import { baseUrl, fbShareBaseURL, getHistoricalReferralGraphURL, getRemeshireBrokerageURL, getPaymentURL, getProfileURL, getReferralURL, twitterShareBaseURL, twitterShareEndURL, whatsappShareBaseURL } from '../utils/Urls'
import { FaWhatsapp, FaFacebookF, FaTwitter } from 'react-icons/fa';
import NavBar from '../components/NavBar';
import DataChart from '../components/DataChart';
import { header } from '../utils/Request';

import { WithdrawModal } from '../modals/WithdrawModal';
import toast from 'react-hot-toast';


const rand = () => Math.round(Math.random() * 20 - 10)

export default function Dashboard() {

  const [userData, setUserData] = useState()
  const [Clients, setClients] = useState()
  const [kycDashboardUrl, setkycDashboardUrl] = useState()
  const [brokerage, setBrokerage] = useState()
  const [commission, setCommission] = useState()
  const [closingBalance, setclosingBalance] = useState()
  const [wallet, setWallet] = useState(0)
  const [debitAmount, setDebitAmount] = useState(0)
  const [availableAmount, setAvailableAmount] = useState(0)
  const [paymentStatus, setPaymentStatus] = useState("")
  const [referralLink, setReferralLink] = useState()
  const [refferalDetails, setReferralDetails] = useState([])
  const [historicalData, setHistoricalData] = useState([])
  const [seriesA, setSeriesA] = useState([])
  const [seriesB, setSeriesB] = useState([])
  const [labels, setLabels] = useState([])
  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()
  const [phoneNumber, setPhoneNumber] = useState()

  useEffect(() => {
    getData()
    getProfile()
    getBrokerageCommissionData(firstName)
    getHistoricalData()
    // getPayment()
    // Extract subbroker ID from referral link
    const urlParams = new URLSearchParams(window.location.search)
    const subbrokerId = urlParams.get('sb')
    getBrokerageCommissionData(subbrokerId)

  }, [])

  const getProfile = async () => {
    axios.post(getProfileURL, {
      "phone_number": "",
    }, {
      withCredentials: true,
      headers: header
    }).then((data) => {
      if (data.data.status === "success") {
        localStorage.setItem("firstName", data?.data?.SubbrokerID ?? "")
        setFirstName(data?.data?.SubbrokerID ?? "")
        setLastName(data?.data?.commissionPercentage ?? "")
        setPhoneNumber(data?.data?.Phone_Number)
        // const array1 = []
        // const array2 = []
        // const labels = []
        // data.data['ledgers']?.map((item) => {
        //   labels.push(item.date_of_entry)
        //   if (item['transtype'] == 'DEBIT') {
        //     array2.push(item.amount)
        //   } else {
        //     array1.push(item.amount)
        //   }
        // })
      } else {
        console.log(data.data)
      }
    }).catch((error) => {
      console.log(error)
      console.log("sess")
    })
  }
  const getHistoricalData = async () => {

    console.log("initiating historical data ")
    console.log(phoneNumber)
    console.log(localStorage.getItem('mobileNumber'))
    try {
      let data = await axios.post(getHistoricalReferralGraphURL, {
        // "phone_number": localStorage.getItem('mobileNumber'),
        "phone_number": localStorage.getItem('firstName'),
      })
      if (data.data.status === "success") {
        console.log("value hist")
        console.log(data.data)
        setHistoricalData(data.data['Historical_Referral_Details'])
        // const array1 = []
        // const array2 = []
        // const labels = []

        data.data['Historical_Referral_Details']?.map((item) => {
          // labels.push(item.date)
          // array1.push(item.brokerage)
          // array2.push(item.commission)

        })

        // setLabels(labels)

        // setSeriesA(array1)
        // setSeriesB(array2)

      } else {
        console.log("error data historical")
        console.log(data.data)
      }
    } catch (error) {
      console.log(error)
      console.log("hitrical data error")
    }
  }

  // const getBrokerageCommissionData = async (firstName, subbrokerId) => {
  //   console.log("Getting Brokerage and Commission Data....")
  //   let ccode = document.getElementById('clinetCOdeee');
  //   console.log(localStorage.getItem('firstName'));
  //   let ccodeVal = localStorage.getItem('firstName');

  //   var currentDate = new Date();
  //   var startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  //   var day = startOfMonth.getDate();
  //   var month = startOfMonth.getMonth() + 1; // Months are zero-based
  //   var year = startOfMonth.getFullYear();

  //   // Add leading zeros if necessary
  //   if (day < 10) {
  //     day = '0' + day;
  //   }
  //   if (month < 10) {
  //     month = '0' + month;
  //   }

  //   // Concatenate day, month, and year with "-"
  //   var from_date = day + '-' + month + '-' + year;

  //   // Get the current date
  //   var currentDay = currentDate.getDate();
  //   var currentMonth = currentDate.getMonth() + 1; // Months are zero-based
  //   var currentYear = currentDate.getFullYear();

  //   // Add leading zeros if necessary
  //   if (currentDay < 10) {
  //     currentDay = '0' + currentDay;
  //   }
  //   if (currentMonth < 10) {
  //     currentMonth = '0' + currentMonth;
  //   }

  //   // Concatenate day, month, and year with "-"
  //   var to_date = currentDay + '-' + currentMonth + '-' + currentYear;

  //   console.log(ccodeVal);
  //   console.log(from_date);
  //   console.log(to_date);

  //   let body = {
  //     "subbroker_id": localStorage.getItem('firstName'),
  //     "from_date": from_date,
  //     "to_date": to_date
  //   };
  //   console.log(body);
  //   console.log(getRemeshireBrokerageURL);
  //   console.log("getRemeshireBrokerageURL");

  //   try {
  //     let response = await axios.post(getRemeshireBrokerageURL, body);
  //     console.log(response);
  //     if (response.status === 200) { // Assuming status 200 indicates success
  //       console.log(response.data['result']);
  //       let reports = response.data['result'];
  //       console.log(reports);

  //       const referralData = [];
  //       const array1 = []; // For brokerage values
  //       const array2 = []; // For commission values
  //       const labels = [1, 2, 3, 4, 5, 6, 7];
  //       let totalBrokerage = 0;
  //       let totalCommission = 0;
  //       let clientCodeCount = 0;
  //       const reportObject = reports[0];

  //       const tempReferralData = []; // Temporary array to hold referral data

  //       for (const clientCode in reportObject) {
  //         if (reportObject.hasOwnProperty(clientCode)) {
  //           const data = reportObject[clientCode];
  //           tempReferralData.push({ clientCode, brokerage: data.brokerage, commission: data.commision });
  //           totalBrokerage += data.brokerage;
  //           totalCommission += data.commision;
  //           clientCodeCount++;
  //         }
  //       }

  //       // Sort the tempReferralData array based on brokerage in descending order
  //       tempReferralData.sort((a, b) => b.brokerage - a.brokerage);

  //       // Push the sorted data to referralData and update the other arrays
  //       for (const data of tempReferralData) {
  //         referralData.push(data);
  //         array1.push(data.brokerage);
  //         array2.push(data.commision);
  //       }

  //       // Update the state with the sorted data
  //       setReferralDetails(referralData);

  //       // Update other states
  //       setSeriesA(array1);
  //       setSeriesB(array2);
  //       setLabels(labels);
  //       setClients(clientCodeCount);
  //       setBrokerage(totalBrokerage.toFixed(2));
  //       setCommission(totalCommission.toFixed(2));
  //     } else {
  //       alert(response.statusText);
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // }


  const getBrokerageCommissionData = async (firstName, subbrokerId) => {
    console.log("Getting Brokerage and Commission Data....")
    let ccode = document.getElementById('clinetCOdeee')
    console.log(localStorage.getItem('firstName'))
    let ccodeVal = localStorage.getItem('firstName')
    var currentDate = new Date();
    var startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    var day = startOfMonth.getDate();
    var month = startOfMonth.getMonth() + 1; // Months are zero-based
    var year = startOfMonth.getFullYear();
    // Add leading zeros if necessary
    if (day < 10) {
      day = '0' + day;
    }
    if (month < 10) {
      month = '0' + month;
    }
    // Concatenate day, month, and year with "-"
    var from_date = day + '-' + month + '-' + year;

    // Get the current date
    var currentDay = currentDate.getDate();
    var currentMonth = currentDate.getMonth() + 1; // Months are zero-based
    var currentYear = currentDate.getFullYear();
    // Add leading zeros if necessary
    if (currentDay < 10) {
      currentDay = '0' + currentDay;
    }
    if (currentMonth < 10) {
      currentMonth = '0' + currentMonth;
    }
    // Concatenate day, month, and year with "-"
    var to_date = currentDay + '-' + currentMonth + '-' + currentYear;

    console.log(ccodeVal);
    console.log(from_date);
    console.log(to_date);
    let body = {
      "subbroker_id": localStorage.getItem('firstName'),
      "from_date": from_date,
      "to_date": to_date
    }
    console.log(body)
    console.log(getRemeshireBrokerageURL)
    console.log("getRemeshireBrokerageURL")
    try {
      let response = await axios.post(getRemeshireBrokerageURL, body);
      console.log(response);
      if (response.status === 200) { // Assuming status 200 indicates success
        console.log(response.data['result']);
        let reports = response.data['result'];
        console.log(reports)

        const referralData = [];
        const array1 = []; // For brokerage values
        const array2 = []; // For commission values
        const labels = [1, 2, 3, 4, 5, 6, 7]
        let totalBrokerage = 0;
        let totalCommission = 0;
        let clientCodeCount = 0;
        const reportObject = reports[0];

        for (const clientCode in reportObject) {
          if (reportObject.hasOwnProperty(clientCode)) {
            const data = reportObject[clientCode];
            referralData.push({ clientCode, brokerage: data.brokerage, commission: data.commision, closingBalance: data.closing_balance });
            array1.push(data.brokerage);
            array2.push(data.commision);
            totalBrokerage += data.brokerage;
            totalCommission += data.commision;
            clientCodeCount++;
          }
        }
        setReferralDetails(referralData);
        setSeriesA(array1)
        setSeriesB(array2)
        setLabels(labels)
        setClients(clientCodeCount)
        setBrokerage(totalBrokerage.toFixed(2))
        setCommission(totalCommission.toFixed(2))
      } else {
        alert(response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }


  const getData = async () => {
    try {
      let data = await axios.post(getReferralURL, {
        "phone_number": localStorage.getItem('mobileNumber'),
      }, {
        withCredentials: true,
        headers: header
      })
      if (data.data.status === "success") {

        setUserData(data.data)
        console.log(data.data)
        console.log(data.data.Referral_Details)
        // setClients(data.data?.Clients ?? 0)
        // setBrokerage(data.data?.Total_Brokerage.toString() ?? "0")
        // setCommission(data.data?.Total_Commission.toString() ?? "0")
        setWallet(data?.data?.Wallet_Amount.toFixed(2) ?? 0)
        setReferralLink(baseUrl + data.data?.Referral_Link ?? baseUrl)
        // setReferralDetails(data.data?.Referral_Details)
        setDebitAmount(data?.data?.debitAmount.toFixed(2) ?? 0)
        setAvailableAmount(data?.data?.availableAmount.toFixed(2))
        setkycDashboardUrl(data?.data?.kyc_url)


      } else {
        console.log(data.data)
      }
    } catch (error) {
    }
  }

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
    toast.success('Link copied to clipboard!');

  }

  return (

    <div>
      <NavBar kycDashboardUrl={kycDashboardUrl} />

      <div className='mt-10 shadow rounded-t-2xl bg-white min-h-screen p-4 container mx-auto'>

        <div className='container mx-auto'>
          <div className='lg:flex sm:flex-none md:flex-none'>

            <div className='rounded p-4 shadow flex-1 m-2'>
              <div className='flex justify-between'>
                <div className=''>
                  <p className='font-medium text-gray-600'> C L I E N T S</p>
                  <p className='text-2xl font-bold'>{Clients ?? "..."}</p>

                  <div className='flex mt-4'>
                    <ArrowSmUpIcon className='h-5 text-green-500' />
                    <p className='text-green-500 text-sm'> Since Last Update</p>
                  </div>

                </div>

                <UsersIcon className='h-12 rounded-full bg-gray-100 p-2 text-gray-400 ' />

              </div>

            </div>

            <div className='rounded p-4 shadow flex-1 m-2'>
              <div className='flex justify-between'>
                <div className=''>
                  <p className='font-medium text-gray-600'>B R O K E R A G E</p>
                  <p className='text-2xl font-bold'>{brokerage ?? "..."}</p>

                  <div className='flex mt-4'>
                    <ArrowSmUpIcon className='h-5 text-green-500' />
                    <p className='text-green-500 text-sm'> Since Last Update</p>
                  </div>

                </div>

                <CurrencyRupeeIcon className='h-12 rounded-full bg-gray-100 p-2 text-gray-400 ' />

              </div>

            </div>
            <div className='rounded p-4 shadow flex-1 m-2'>
              <div className='flex justify-between'>
                <div className=''>
                  <p className='font-medium text-gray-600'>C O M M I S S I O N</p>
                  <p className='text-2xl font-bold'>{commission ?? "..."}</p>

                  <div className='flex mt-4'>
                    <ArrowSmUpIcon className='h-5 text-green-500' />
                    <p className='text-green-500 text-sm'> Since Last Update</p>
                  </div>

                </div>

                <ReceiptTaxIcon className='h-12 rounded-full bg-gray-100 p-2 text-gray-400 ' />

              </div>
            </div>
            <div className='rounded p-4 shadow flex-1 m-2'>
              <div className='flex justify-between'>
                <div className=''>
                  <p className='font-medium text-gray-600'> W A L L E T </p>
                  <p className='text-2xl font-bold'>{wallet ?? "..."}</p>

                  {paymentStatus === "Processing" ?
                    <p className='text-yellow-500 text-sm'>Payment Request Initiated</p>
                    :
                    wallet ?
                      wallet > 0 ?
                        <WithdrawModal amount={wallet} debitAmount={debitAmount} availableAmount={availableAmount} />
                        : <div />
                      : <div />

                  }
                </div>

                <CreditCardIcon className='h-12 rounded-full bg-gray-100 p-2 text-gray-400 ' />

              </div>
            </div>
          </div>
        </div>
        <div className='lg:flex md:flex-auto sm:flex-none'>
          {/* {
            historicalData.length !== 0 ?
              <DataChart title='Brokerage and Commission' data={
                {
                  labels: labels,
                  datasets: [
                    {
                      type: 'line',
                      label: 'Brokerage',
                      borderColor: 'rgb(54, 162, 235)',
                      borderWidth: 2,
                      fill: false,
                      data: seriesA,
                    },
                    {
                      type: 'line',
                      label: 'Commission',
                      borderColor: 'rgb(255, 99, 132)',
                      borderWidth: 2,
                      fill: false,
                      data: seriesB,
                    },

                  ],
                }
              } />
              :
              <DataChart title='Brokerage and Commission' data={
                {
                  labels: labels,
                  datasets: [
                    {
                      type: 'line',
                      label: 'Brokerage',
                      borderColor: 'rgb(54, 162, 235)',
                      borderWidth: 2,
                      fill: false,
                      data: [],
                    },
                    {
                      type: 'line',
                      label: 'Commission',
                      borderColor: 'rgb(255, 99, 132)',
                      borderWidth: 2,
                      fill: false,
                      data: [],
                    },

                  ],
                }
              } />
          } */}
          <DataChart title='Brokerage and Commission' data={
            {
              labels: labels,
              datasets: [
                {
                  type: 'line',
                  label: 'Brokerage',
                  borderColor: 'rgb(54, 162, 235)',
                  borderWidth: 2,
                  fill: false,
                  data: seriesA,
                },
                {
                  type: 'line',
                  label: 'Commission',
                  borderColor: 'rgb(255, 99, 132)',
                  borderWidth: 2,
                  fill: false,
                  data: seriesB,
                },

              ],
            }
          } />
          <div className='shadow p-4 mx-6 mt-4 flex-1'>
            <p className='font-bold'>Net Balance Over Clients <span id="clinetCOdeee">{firstName}</span></p>
            <div className='overflow-x-auto bandcTable'>
              <table className="table-auto mt-8 ml-1">
                <thead>
                  <tr className='bg-gray-200 border border-gray-300'>
                    <th className="w-1/6 ... p-4 border-r border-gray-300">Client Code</th>
                    <th className="w-1/6 ... p-4 ">Net Balance</th>
                    {/* <th className="w-1/3 ... p-4 ">Brokerage</th>
                    <th className="w-1/3 ... p-4 border-l border-gray-300">Commission</th> */}
                  </tr>
                </thead>
                <tbody>
                  {refferalDetails?.map((item, index) => {
                    return (
                      <tr key={index} className='border border-gray-300'>
                        <td className='p-2 mx-auto text-center border-r border-gray-300'>{item.clientCode}</td>
                        <td className='p-2 mx-auto text-center border-r border-gray-300'>{item.closingBalance}</td>
                        {/* <td className='p-2 mx-auto text-center border-r border-gray-300'>{parseFloat(item.brokerage).toFixed(2)}</td>
                        <td className='p-2 mx-auto text-center border-r border-gray-300'>{parseFloat(item.commission).toFixed(2)}</td> */}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className='shadow-md mx-6 mt-8 p-4'>
          <p className='font-bold'>Your Unique referral link</p>

          <div className='border p-2 mt-2 flex justify-between'>
            <p className='font-light text-sm overflow-x-hidden text-gray-400'>{referralLink}</p>
            <ClipboardCopyIcon className='h-6 text-gray-400 w-8 my-auto ml-1' onClick={() => copyToClipboard(referralLink)} />


          </div>

          <p className='font-bold mt-3'> Share on Social Media</p>

          <div className='flex mt-3 '>
            <div className='bg-green-500 rounded-full p-2 text-2xl mr-2'>
              <FaWhatsapp className='text-white' onClick={() => window.open(whatsappShareBaseURL + referralLink)} />
            </div>

            <div className='bg-blue-500 rounded-full p-2 text-2xl mx-1'>
              <FaFacebookF className='text-white' onClick={() => window.open(fbShareBaseURL + referralLink)} />
            </div>


            <div className='bg-blue-400 rounded-full p-2 text-2xl ml-2'>
              <FaTwitter className='text-white' onClick={() => window.open(twitterShareBaseURL + referralLink + twitterShareEndURL)} />
            </div>

          </div>


        </div>


      </div>
    </div>
  )
}
