import React, { useEffect, useState } from 'react';
import NavBar from '../components/NavBar';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { getReferralURL, getSubbrokerContactsURL, getProfileURL, synapsesFastTokenUser, synapsesFastTokenPass, synapsesFastTokenUrl } from '../utils/Urls';
import { header } from '../utils/Request';

export default function Contacts() {
    const [kycDashboardUrl, setkycDashboardUrl] = useState()
    const [firstName, setFirstName] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [contactsData, setContactsData] = useState([])

    const history = useHistory();

    const handleClientIdClick = (clientId) => {
        history.push(`/contactview/${clientId}`);
    };

    const handleCallClient = (CONTACT_NUMBER1) => { }
    const handleEmailClient = (EMAIL1) => { }

    useEffect(() => {
        getData();
        getProfile();
    }, []);

    useEffect(() => {
        if (firstName) {
            loadContactDetails();
        }
    }, [firstName]);

    const getData = async () => {
        try {
            let data = await axios.post(getReferralURL, {
                "phone_number": localStorage.getItem('mobileNumber'),
            }, {
                withCredentials: true,
                headers: header
            })
            if (data.data.status === "success") {
                setkycDashboardUrl(data?.data?.kyc_url)
            } else {
                console.log(data.data)
            }
        } catch (error) {
        }
    }

    const synpfastToken = async () => {
        try {
            const url = `${synapsesFastTokenUrl}username=${encodeURIComponent(synapsesFastTokenUser)}&password=${encodeURIComponent(synapsesFastTokenPass)}`;
            console.log(url)
            const response = await axios.post(url);
            const token = response.data.access_token;
            return token;
        } catch (error) {
            console.error('Error fetching token:', error);
            throw error;
        }
    };

    const loadContactDetails = async () => {
        try {
            const token = await synpfastToken();
            const synpheaders = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            const url = `${getSubbrokerContactsURL}subbroker_id=${encodeURIComponent(firstName)}`;
            const response = await axios.get(url, { headers: synpheaders });
            if (response.status === 200) {
                let data = response.data
                setContactsData(data)
            } else {
                alert(response.detail);
            }
        } catch (error) {
            console.error('Error fetching holdings data:', error);
        }
    };

    const getProfile = async () => {
        try {
            const response = await axios.post(getProfileURL, { "phone_number": "" }, {
                withCredentials: true,
                headers: header,
            });

            if (response.data.status === "success") {
                setFirstName(response.data?.SubbrokerID ?? "");
                setPhoneNumber(response.data?.Phone_Number ?? "");
                const array1 = [];
                const array2 = [];
                const labels = [];

                response.data['ledgers']?.forEach((item) => {
                    labels.push(item.date_of_entry);
                    if (item['transtype'] === 'DEBIT') {
                        array2.push(item.amount);
                    } else {
                        array1.push(item.amount);
                    }
                });
            } else {
                console.log(response.data);
            }
        } catch (error) {
            console.log(error);
            console.log("sess");
        }
    };

    return (
        <div className='bg-gray-50'>
            <NavBar kycDashboardUrl={kycDashboardUrl} />
            <div className='mt-10 rounded-t-2xl bg-white  p-4 container mx-auto' style={{ minHeight: '90vh' }}>
                <div className='row'>
                    <div className='col-md-12 col-xl-12'>
                        <p className='font-medium my-auto ml-2'> Active Codes Under - {firstName ?? "..."}</p>
                    </div>
                </div>
                <div className='p-4 mt-6'>
                    {
                        contactsData.length !== 0 ?
                            <div className='lg:flex md:flex-auto sm:flex-none'>
                                <div className='flex-1'>
                                    <div className='overflow-x-auto'>
                                        <div className='table-responsive'>
                                            <div className='table'>
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Client Id</th>
                                                        <th>Name</th>
                                                        <th className='text-center'>Mobile Number</th>
                                                        <th className='text-center'>Email Id</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {contactsData?.map((item) => {
                                                        return (
                                                            <tr key={item?.CLIENT_ID} onClick={() => handleClientIdClick(item?.CLIENT_ID)}>
                                                                <td><i class="fa-solid fa-address-book" aria-hidden="true"></i></td>
                                                                <td>{item?.CLIENT_ID}</td>
                                                                <td>{item?.CLIENT_NAME}</td>
                                                                <td className='text-left'><i className="fa-solid fa-phone color-medium" aria-hidden="true" onClick={() => handleCallClient(item?.CONTACT_NUMBER1)}></i></td>
                                                                <td className='text-left'><i className="fa-solid fa-envelope color-medium" aria-hidden="true" onClick={() => handleEmailClient(item?.EMAIL1)}></i></td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : (
                                <div className='text-center mt-4'>No data found</div>
                            )
                    }
                </div>
            </div>
        </div>
    );
}
